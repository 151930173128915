import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import blogsArray from "../../blogData.js";

const LastestNews = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(blogsArray);
  }, []);

  const fivePosts = blogs.slice(-5).reverse();
  // const fivePosts = blogs.slice(0, 5);

  // Filtering blogs where isMostview is true
  const filterBlog = blogs.filter((blog) => blog.isMostview === true);

  return (
    <Wrapper>
      <div id="wrapper" style={{ paddingTop: "0px" }}>
        <div className="content">
          <section style={{ paddingTop: "0px" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="main-container fl-wrap fix-container-init">
                    <div className="section-title">
                      <h2>Recent Publications</h2>
                    </div>

                    <div className="blog_part">
                      <div className="main-container fl-wrap fix-container-init">
                        <div className="list-post-wrap">
                          {fivePosts &&
                            fivePosts.map((post) => (
                              <div key={post.id} className="list-post fl-wrap">
                                <div className="list-post-media">
                                  <Link to={`/all-issues-details/${post.id}`}>
                                    <div className="bg-wrap">
                                      <div
                                        className="bg"
                                        style={{
                                          backgroundImage: `url(${post.image})`,
                                        }}
                                      ></div>
                                    </div>
                                  </Link>
                                  <span className="post-media_title">
                                    &copy; {post.copyright}
                                  </span>
                                </div>
                                <div className="list-post-content">
                                  <Link className="post-category-marker" to="#">
                                    {post.category}
                                  </Link>
                                  <h3>
                                    <Link to={`/all-issues-details/${post.id}`}>
                                      {post.title}
                                    </Link>
                                  </h3>
                                  <span className="post-date">
                                    <i className="far fa-clock"></i> {post.date}
                                  </span>

                                  <div className="author-link">
                                    <Link to="#">
                                      <img
                                        src={post.authorImage}
                                        alt={post.author}
                                        style={{
                                          border: "1px solid #2A276C",
                                        }}
                                      />
                                      <span>{post.author}</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="sidebar-content fl-wrap fix-bar">
                    <div className="box-widget fl-wrap">
                      <div className="box-widget-content">
                        <div className="tabs-container">
                          <div className="content-tabs-wrap tabs-act tabs-widget fl-wrap">
                            <div className="content-tabs fl-wrap">
                              <ul className="tabs-menu fl-wrap no-list-style w-100">
                                <li
                                  className="w-100"
                                  style={{ background: "#2A276C" }}
                                >
                                  <Link to="" style={{ background: "#2A276C" }}>
                                    Most Read
                                  </Link>
                                </li>
                              </ul>
                            </div>

                            <div className="tabs-container">
                              <div className="tab-content first-tab">
                                <div className="post-widget-container fl-wrap">
                                  {filterBlog.map((blog) => (
                                    <div
                                      key={blog.id}
                                      className="post-widget-item fl-wrap"
                                    >
                                      <div className="post-widget-item-media">
                                        <Link
                                          to={`/all-issues-details/${blog.id}`}
                                        >
                                          <img
                                            src={blog.image}
                                            alt={blog.title}
                                          />
                                        </Link>
                                      </div>
                                      <div className="post-widget-item-content">
                                        <h4>
                                          <Link
                                            to={`/all-issues-details/${blog.id}`}
                                          >
                                            {blog.title}
                                          </Link>
                                        </h4>
                                        <ul className="pwic_opt">
                                          <li>
                                            <span>
                                              <i className="far fa-clock"></i>{" "}
                                              {blog.date}
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  #ajax-content {
    /* padding: 10px 0; */
    overflow: hidden;
    height: auto;
    min-height: 0px !important;
  }
  .ideaboxWeather:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-size: cover;
    background-attachment: scroll;
    background-position: center;
    background-repeat: repeat;
    background-origin: content-box;
    background-image: url("../images/bg/7.jpg");
    z-index: 1;
  }
  section {
    float: left;
    width: 100%;
    position: relative;
    padding: 0px 0 !important;
    z-index: 10;
    overflow: hidden;
  }

  @media screen and (max-width: 320px) {
  }
`;

export default LastestNews;
