import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const JournalOverview = () => {
  return (
    <Wrapper style={{ padding: "0" }}>
      <div id="wrapper" style={{ padding: "0" }}>
        <div className="content" style={{ marginTop: "70px" }}>
          <div className="breadcrumbs-header fl-wrap">
            <div className="container">
              <div className="breadcrumbs-header_url">
                <Link to="/">Home</Link>
                {/* <span>Journal Overview</span> */}
              </div>
            </div>
            <div className="pwh_bg"></div>
          </div>

          <section>
            <div className="container">
              <div className="row">
                <div className="mb-4">
                  <div className="section-title sect_dec">
                    {/* <h2>Our Story</h2> */}
                    <h2>1. Description</h2>
                    {/* <h4>Who we are</h4> */}
                  </div>
                  <div className="about-wrap">
                    <p className="ppp_text">
                      The South Asian Journal of Law & Policy (SAJLP) is a
                      leading, open-access platform dedicated to fostering
                      innovative socio-legal scholarship with a special focus on
                      the South Asian region. As a non-profit, single-blind
                      peer-reviewed journal, SAJLP provides a rigorous forum for
                      independent thinkers, legal practitioners, jurists,
                      economists, and promising students to share their
                      groundbreaking research and thought-provoking insights. By
                      embracing a diverse range of perspectives and cutting-edge
                      methodologies, SAJLP aims to shape the future of law and
                      policy in South Asia and beyond.
                    </p>

                    {/* <Link
                        to="https://vimeo.com/225223404"
                        target="_blank"
                        className="btn float-btn color-btn image-popup"
                      >
                        <span>Our Story Video</span>
                        <i className="fas fa-caret-right"></i>
                      </Link> */}
                  </div>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>2. Aims & Scope</h2>
                  </div>
                  <div className="about-wrap">
                    <p className="ppp_text">
                      SAJLP is committed to publishing high-quality scholarly
                      research that pushes the boundaries of knowledge in the
                      fields of social sciences, law and humanities, social
                      policies, and related disciplines. We welcome submissions
                      of leading research articles, review articles, thematic
                      discussions, case notes and analyses, and book reviews
                      that address current and historical issues. Our editorial
                      board is particularly interested in cutting-edge research
                      that offers innovative solutions to pressing policy and
                      legal challenges. We also provide a platform for
                      thoughtful dialogue on contemporary global and socio-legal
                      issues. While we typically publish one issue per year, we
                      may release special issues to address emerging topics or
                      significant events.
                    </p>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>3. Journal Metrics, Abstracting & Indexing</h2>
                  </div>
                  <div className="about-wrap">
                    <p className="ppp_text">
                      The ISSN of the journal is 2616-8197 (
                      <Link
                        className="linnk"
                        to="https://portal.issn.org/resource/ISSN/2616-8197"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://portal.issn.org/resource/ISSN/2616-8197
                      </Link>
                      ). It looks forward to getting indexed in Web of Sciences,
                      DORA, and Scopus. SAJLP is committed to following the
                      principles of DOAJ and COPE to ensure best practices.
                    </p>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>4. Open Access Statement</h2>
                  </div>
                  <div className="about-wrap">
                    <p className="ppp_text">
                      SAJLP publishes certain issues and the articles contained
                      therein in an open access (OA) format, subject to the
                      terms of the Creative Commons
                      Attribution-NonCommercial-ShareAlike 4.0 (CC-BY-NC-SA 4.0)
                      license. The full issues of the journal are freely and
                      unrestrictedly available on our website. Our journal
                      provides “gold open access” to the articles, with
                      immediate, time-free publication without embargo. Our
                      authors can publish their work in our journal upon payment
                      of a nominal publication fee, following the successful
                      completion of the “single-blind peer review” process. The
                      author's article can be freely downloaded and copied in
                      electronic form; it can be used without alteration, with
                      attribution to the source. The use of articles cannot
                      serve commercial purposes, only the use of articles for
                      one's own purposes is permitted. Our authors can freely
                      deposit their work in other (institutional or other)
                      repositories without embargo - including the submission
                      (Submitted version), accepted (Accepted version), and
                      published (Published version) versions. SAJLP therefore
                      fulfills DOAJ definitions of open access.
                    </p>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>5. Professional & Ethical Guidelines</h2>
                  </div>
                  <div className="about-wrap">
                    <p className="ppp_text">
                      We fully comply with the Code of Conduct and the Best
                      Practice Guidelines of the Committee on Publication Ethics
                      (COPE). The editors of SAJLP take the responsibility to
                      ensure high-quality scientific publications that meet
                      standards of academic excellence and to enforce a rigorous
                      peer review together with strict ethical policies and
                      standards in the field of scholarly publication. In case
                      of an ethical issue, all requisite action is taken.
                      Ethical issues that might be raised by readers of the
                      journal will be examined by the editorial office following
                      procedures in line with COPE guidelines. SAJLP abides by
                      the COPE's Principles of Transparency and Best Practice in
                      Scholarly Publishing. For handling potentially unethical
                      behavior by authors, reviewers, or editors, our journal
                      adheres to COPE's policies. All editorial members at SAJLP
                      are aware of COPE policies. The directions for authors of
                      journals include information on ethical considerations for
                      submitting manuscripts. The editorial staff will
                      investigate any ethical concerns brought up by journal
                      readers using the COPE-recommended processes. The
                      Editorial Board can resolve disagreements over the
                      reliability of research reported in articles that have
                      been published. When appropriate, we will submit
                      disagreements regarding authorship, data ownership, author
                      misbehavior, etc. to external bodies. Authors are urged to
                      address any claims that have been made against them with
                      evidence. We adhere to COPE principles, notably How to
                      Spot Authorship Problems when handling authorship
                      disputes. Usually, the authorship can be altered via a
                      correction if all authors agree. If not, we need a
                      credible declaration concerning who is eligible for
                      authorship from the institution(s) of the authors. The
                      journal takes issues of copyright infringement,
                      plagiarism, or other breaches of best practice in
                      publication very seriously.
                    </p>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>6. Copyright Statement</h2>
                  </div>
                  <div className="about-wrap">
                    <p className="ppp_text">
                      Our journal's Editorial Board is committed to making
                      submitted manuscripts publicly accessible (Open Access -
                      OA) under clear copyright and licensing terms. In light of
                      this, under the present terms, we provide authors with the
                      opportunity for publication. By submitting the manuscript
                      created by them to the Editorial Board and handing it over
                      to the Editorial Office, the author consents to the
                      publisher publishing an article edited from this
                      manuscript in the journal. The author acknowledges that
                      the publishing and usage rights related to the article are
                      realized according to the present terms after the first
                      publication. The author consents to the publisher making
                      the article freely accessible and downloadable on its
                      official publishing website without any temporal
                      restrictions. The exclusive right to the first publication
                      of the article belongs solely to the publisher. The author
                      accepts that the publisher publishes the article in such a
                      manner that the usage rights of the article are governed
                      by the Creative Commons
                      Attribution-NonCommercial-ShareAlike 4.0 (CC-BY-NC-SA 4.0)
                      license terms for any third party after the first
                      publication. Within the framework of this license, the
                      publisher may convert the article into any electronic
                      format and copy the article to a computer or electronic
                      storage medium. The publisher may share the publication
                      and usage rights of the article with any third party or
                      exercise these rights jointly with any third party. The
                      publisher may act on behalf of the author in cases of
                      copyright infringement, unauthorized use, and actions that
                      violate intellectual property rights related to the
                      article. It may exercise all rights that the “CC BY NC SA
                      4.0” license terms enable for third parties. With the
                      submission, the author warrants that they are the
                      exclusive author of the article along with the specified
                      co-authors—or has the consent of all co-authors to publish
                      the article in its current form—that the article is their
                      own original intellectual creation, and that they have not
                      partially or wholly transferred the first publication
                      right of the article to a third party before submission.
                      Based on these, the author has the right to publish the
                      article and thus possesses all entitlements to authorize
                      the publisher to publish the article for the first time or
                      to exercise the usage rights related to the article
                      according to the present terms. The author warrants that
                      the article does not contain plagiarism, that the
                      descriptions are accurate and true to the best of the
                      author's knowledge, and that the article does not contain
                      defamation, obscene, or infringing on the copyrights,
                      privacy, or any other rights of third parties, or
                      otherwise violates the law, and that the manuscript is not
                      under consideration or published, either partially or
                      wholly, in any other language, in any other journal. The
                      author acknowledges and accepts that if they wish to
                      publish materials in their article whose usage rights are
                      owned by other rights holders, publishers, or authors, it
                      is solely their responsibility to obtain the appropriate
                      publication and usage permissions from these rights
                      holders before submitting their manuscript to the SAJLP
                      authorities.
                    </p>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>7. Privacy Statement</h2>
                  </div>
                  <div className="about-wrap">
                    <p className="ppp_text">
                      In the absence of the General Data Protection Regulation
                      (GDPR) in Bangladesh, SAJLP will follow the principles of
                      GDPR Regulation of the European Union no. 2016/679. SAJLP
                      processes personal data confidentially and takes all
                      necessary security, technical, and organizational measures
                      to ensure the security of the data. In certain cases, the
                      processing, storage, and transmission of the provided data
                      are mandatory by-laws, of which the publisher informs its
                      clients separately. The publisher does not verify the
                      accuracy of the provided personal data. The accuracy of
                      the provided personal data is the sole responsibility of
                      the person providing them. We draw the attention of data
                      providers to the fact that if they provide personal data
                      that is not their own, the data provider is obliged to
                      inform the data subject and, if necessary, obtain their
                      consent. Also, the publisher selects and operates IT tools
                      used for processing personal data in a way that ensures
                      the processed data remains accessible to authorized
                      persons and their integrity is maintained at all times.
                      The publisher protects the data against unauthorized
                      access, alteration, transmission, disclosure, deletion, or
                      destruction, as well as against accidental destruction
                      with appropriate measures. The publisher ensures the
                      security of data processing with technical and
                      organizational measures that provide a level of protection
                      commensurate with the risks associated with data
                      processing. For further questions, requests, complain,
                      or incident reports, please contact the Management &
                      Policy Chief at
                      <Link
                        className="linnk"
                        to="mailto:aditya.oikya@gmail.com"
                      >
                        {" "}
                        aditya.oikya@gmail.com
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sec-dec"></div>
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .section-title {
    margin-bottom: 20px !important;
  }
  .ppp_text {
    text-align: justify !important;
  }
  .linnk {
    color: #0563c1;
  }
  .ppp_text {
    font-size: 14px !important;
    color: #000 !important;
  }
`;

export default JournalOverview;
