import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Registration = () => {
  return (
    <Wrapper style={{ padding: "0px" }}>
      <div id="wrapper">
        <div className="content" style={{ marginTop: "70px" }}>
          <section className="hero-section error-section">
            <div className="bg-wrap hero-section_bg">
              <div
                className="bg"
                data-bg="images/bg/6.jpg"
                style={{ backgroundImage: `url(/images/bg/1.jpg)` }}
              ></div>
            </div>
            <div className="container">
              <div className="error-wrap">
                <h2 className="fs-4 text-white text-uppercase">Registration</h2>

                <div className="clearfix"></div>
                <div className="custom-form">
                  <form className="main-register-form p-4">
                    <label className="text-white">
                      Full Name <span className="text-danger">*</span>
                    </label>
                    <input name="name" type="text" />
                    <label className="text-white">
                      Email Address <span className="text-danger">*</span>
                    </label>
                    <input name="email" type="text" />
                    <label className="text-white">
                      Password <span className="text-danger">*</span>
                    </label>
                    <input name="password" type="password" />
                    <button type="submit" className="log-submit-btn color-bg">
                      <span>Register</span>
                    </button>

                    <span className="text-white">
                      <Link className="text-white" to="/login">
                        Already signed up? Log in
                      </Link>{" "}
                      here.
                    </span>
                  </form>
                </div>

                <div className="clearfix"></div>
                <p>Or</p>
                <Link to="/" className="btn color-btn flat-btn">
                  <span>Back to Home Page</span>
                  <i className="fas fa-caret-right"></i>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .aside-panel .vis_secnav {
    display: none !important;
  }

  @media screen and (max-width: 320px) {
  }
`;

export default Registration;
