import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Footer = () => {
  // const scrollToTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <Wrapper style={{ padding: "0px" }}>
      <div id="wrapper">
        <footer className="fl-wrap main-footer">
          <div className="container">
            <div className="footer-widget-wrap fl-wrap">
              <div className="row">
                <div className="col-md-4">
                  <div className="footer-widget">
                    <div className="footer-widget-content">
                      <Link to="/" className="footer-logo">
                        <img
                          src="/images/sajlp-logo11.png"
                          alt=""
                          style={{ width: "180px", height: "180px" }}
                        />
                      </Link>

                      <div className="footer-social fl-wrap">
                        <ul>
                          <li>
                            <Link
                              to="https://www.facebook.com/sajlp.org/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-facebook-f"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#" target="_blank">
                              <i className="fab fa-twitter"></i>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://www.linkedin.com/"
                              target="_blank"
                            >
                              <i className="fab fa-linkedin"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-2">
                  <div className="footer-widget d-none">
                    <div className="footer-widget-title">Categories</div>
                    <div className="footer-widget-content">
                      <div className="footer-list footer-box fl-wrap">
                        <ul>
                          <li>
                            <Link to="#">Politics</Link>
                          </li>
                          <li>
                            <Link to="#">Technology</Link>
                          </li>
                          <li>
                            <Link to="#">Business</Link>
                          </li>
                          <li>
                            <Link to="#">Sports</Link>
                          </li>
                          <li>
                            <Link to="#">Science</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="col-md-4">
                  <div className="footer-widget">
                    <div className="footer-widget-title">Links</div>
                    <div className="footer-widget-content">
                      <div className="footer-list footer-box fl-wrap">
                        <ul>
                          <li>
                            <Link to="/">Home</Link>
                          </li>
                          <li>
                            <Link to="all-issues">All Issues</Link>
                          </li>
                          {/* <li>
                            <Link to="/about">About</Link>
                          </li> */}
                          <li>
                            <Link to="/journal-overview">Journal Overview</Link>
                          </li>
                          <li>
                            <Link to="/editorial-policy">Editorial Policy</Link>
                          </li>
                          <li>
                            <Link to="/submission-guidelines">
                              Submission Guidelines
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/author">Author</Link>
                          </li> */}
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="footer-widget">
                    <div className="footer-widget-title">Subscribe</div>
                    <div className="footer-widget-content">
                      <div className="subcribe-form fl-wrap">
                        <p>
                          Want to be notified when we launch our new issue? Just
                          sign up and we'll send you a notification by email.
                        </p>
                        <form id="subscribe" className="fl-wrap">
                          <input
                            className="enteremail"
                            name="email"
                            id="subscribe-email"
                            placeholder="Your Email"
                            spellCheck="false"
                            type="text"
                          />
                          <button
                            type="submit"
                            id="subscribe-button"
                            className="subscribe-button color-bg"
                          >
                            Send
                          </button>
                          <label
                            htmlFor="subscribe-email"
                            className="subscribe-message"
                          ></label>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom fl-wrap">
            <div className="container">
              <div className="copyright">
                {/* <span>&#169; Gmag 2022</span> . All rights reserved. */}
                <div className="copyrightt">
                  <Link
                    to="https://www.ekattorit.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <small className="small">
                      <small
                        className="footer text-white tttt"
                        style={{ fontSize: "10px" }}
                      >
                        &copy; Copyright {currentYear},
                      </small>
                      &nbsp;
                      <small
                        className="footer text-white tttt"
                        style={{ color: "#fff", fontSize: "10px" }}
                      >
                        Designed & Developed By&nbsp;
                        <span style={{ color: "red" }}>EKATTOR</span>
                        <span style={{ color: "green" }}> iT</span>
                      </small>
                    </small>
                  </Link>
                </div>
              </div>
              {/* <div className="to-top">
              <i className="fas fa-caret-up"></i>
            </div> */}
              {/* <div
                className="to-top"
                onClick={scrollToTop}
                style={{ cursor: "pointer" }}
              >
                <i className="fas fa-caret-up"></i>
              </div> */}

              <div className="subfooter-nav">
                <ul>
                  <li>
                    <Link to="#">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="#">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>

        {/* <!-- footer end--> */}
        {/* <div className="aside-panel">
          <ul>
            <li>
              <a href="#">
                <i className="far fa-podium"></i>
                <span>Politics</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="far fa-atom"></i>
                <span>Technology</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="far fa-user-chart"></i>
                <span>Business</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="far fa-tennis-ball"></i>
                <span>Sports</span>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="far fa-flask"></i>
                <span>Science</span>
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  section {
    float: left;
    width: 100%;
    position: relative;
    padding-bottom: 0px !important;
    z-index: 10;
    overflow: hidden;
  }

  @media screen and (max-width: 425px) {
    .copyright {
      text-align: center !important;
      float: none !important;
    }
    .copyrightt {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
    .subfooter-nav {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
  @media screen and (min-width: 425px) {
    .tttt {
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 320px) {
    .footer {
      font-size: 10px !important;
    }
  }
`;

export default Footer;
