import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div id="wrapper" style={{ padding: "0" }}>
      <Wrapper id="wrapper" style={{ padding: "0" }}>
        <div className="content" style={{ marginTop: "70px" }}>
          <section className="hero-section">
            <div className="bg-wrap hero-section_bg">
              <div
                className="bg"
                style={{ backgroundImage: 'url("/images/bg/8.jpg")' }}
              ></div>
            </div>
            <div className="container">
              <div className="hero-section_title">
                <h2>Contact</h2>
              </div>
              <div className="clearfix"></div>
              <div className="breadcrumbs-list fl-wrap">
                <Link to="/">Home</Link>
                {/* <span>Contacts</span> */}
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="pr-subtitle prs_big">Details</div>

                  <ul className="contacts-list fl-wrap">
                    <li>
                      <span className="texxt">
                        <i className="fal fa-map-marker"></i> Adress :
                      </span>
                      {/* <div className="adress"> */}
                      <span className="texxt">
                        &nbsp;&nbsp;&nbsp;&nbsp;House: SurDhuni, Adv Asad Road,
                        Khalilgonj,
                      </span>
                      {/* <span> Kurigram Sadar, Kurigram 5600 (Bangladesh)</span> */}
                      {/* </div> */}
                    </li>
                    <li>
                      <span className="texxt">
                        <i className="fal fa-map-marker"></i> Adress :
                      </span>
                      <span className="texxt">
                        &nbsp;&nbsp;&nbsp;&nbsp;Kurigram Sadar, Kurigram 5600
                        (Bangladesh)
                      </span>
                    </li>

                    <li>
                      <span className="texxt">
                        <i className="fal fa-envelope"></i> Principle Contact :
                      </span>
                      <span className="texxt">
                        &nbsp;&nbsp;&nbsp;&nbsp;editor.sajlp@gmail.com
                      </span>
                    </li>

                    <li>
                      <span className="texxt">
                        <i className="fal fa-envelope"></i> Support / Complain :
                      </span>
                      <span className="texxt">
                        &nbsp;&nbsp;&nbsp;&nbsp;aditya.oikya@gmail.com
                      </span>
                    </li>
                  </ul>

                  <div className="contact-social fl-wrap">
                    <span className="cs-title">Find us on: </span>
                    <ul>
                      <li>
                        <Link
                          to="https://www.facebook.com/sajlp.org/"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.linkedin.com/" target="_blank">
                          <i className="fab fa-linkedin"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="pad-con fl-wrap">
                    <div className="pr-subtitle prs_big">Write to us</div>

                    <p className="fs-6 text-justify para_text">
                      For any kind of query, feel free to contact us. For
                      support/complain, reach to the Management & Policy Chief.
                    </p>

                    <div id="contact-form" style={{ marginTop: "20px" }}>
                      <div id="message"></div>
                      <form
                        className="custom-form"
                        action="https://gmag.kwst.net/php/contact.php"
                        name="contactform"
                        id="contactform"
                      >
                        <fieldset>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Your Name *"
                            defaultValue=""
                          />
                          <input
                            type="text"
                            name="email"
                            id="email"
                            placeholder="Email Address*"
                            defaultValue=""
                          />
                          <textarea
                            name="comments"
                            id="comments"
                            cols="40"
                            rows="3"
                            placeholder="Your Message:"
                          ></textarea>
                        </fieldset>
                        <button className="btn color-bg float-btn" id="submit">
                          Send message <i className="fas fa-caret-right"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.section`
  .contacts-list {
    padding: 0px !important;
  }
  .contacts-list li span {
    min-width: 0px;
  }

  @media screen and (max-width: 375px) {
    .texxt {
      font-size: 11px !important;
    }
    .para_text {
      font-size: 15px !important;
    }
  }
  @media screen and (max-width: 320px) {
    .texxt {
      font-size: 9px !important;
    }
  }
`;

export default Contact;
