import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
// import ProgressBar from "./components/ProgressBar";
import Header from "./components/Header/Header";
import ScrollUp from "./components/ScrollUp";
import Top from "./components/Top";
import NotFound from "./components/NotFound";
import Loader from "./components/Loader";
import Footer from "./components/Footer/Footer";
// import CookieInfoBar from "./components/CookieInfoBar";

import Home from "./pages/Home/Home";
import AllIssues from "./pages/AllIssues/AllIssues";
import AllIssuesDetails from "./pages/AllIssuesDetails/AllIssuesDetails";
import JournalOverview from "./pages/JournalOverview/JournalOverview";
import EditorialPolicy from "./pages/EditorialPolicy/EditorialPolicy";
import SubmissionGuidelines from "./pages/SubmissionGuidelines/SubmissionGuidelines";
import Contact from "./pages/Contact/Contact";
import Login from "./pages/LoginRegistration/Login";
import Registration from "./pages/LoginRegistration/Registration";

// import About from "./pages/About/About";
// import Business from "./pages/Business/Business";
// import Technology from "./pages/Technology/Technology";
// import Author from "./pages/Author/Author";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Set loading to false after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <Loader />
      ) : (
        <BrowserRouter>
          <div id="main">
            {/* <ProgressBar /> */}
            <Top />
            <ScrollUp />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/all-issues" element={<AllIssues />} />
              <Route path="/all-issues-details/:id" element={<AllIssuesDetails />} />
              <Route path="/journal-overview" element={<JournalOverview />} />
              <Route path="/editorial-policy" element={<EditorialPolicy />} />
              <Route
                path="/submission-guidelines"
                element={<SubmissionGuidelines />}
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route path="/sign-up" element={<Registration />} />
              <Route path="*" element={<NotFound />} />

              {/* <Route path="/about" element={<About />} /> */}
              {/* <Route path="/blog-business" element={<Business />} />
              <Route path="/blog-technology" element={<Technology />} /> */}
              {/* <Route path="/author" element={<Author />} /> */}
            </Routes>
            <Footer />
            {/* <CookieInfoBar /> */}
          </div>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
