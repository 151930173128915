import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import blogsArray from "../../blogData.js";

const AllIssuesDetails = () => {
  // const [activeTab, setActiveTab] = useState("recent");
  const [blogs, setBlogs] = useState([]);
  const [para, setPara] = useState([]);

  useEffect(() => {
    setBlogs(blogsArray);
  }, []);

  const { id } = useParams();
  const [popupOpen, setPopupOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(null);

  // Open popup and set the active image
  const handlePopupOpen = (slide) => {
    setActiveImage(slide);
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    setActiveImage(null);
  };

  const findBlog = blogs.find((blog) => blog.id === parseInt(id));

  useEffect(() => {
    setPara((findBlog && findBlog.paragraph) || []); // Ensure para is always an array
  }, [findBlog]);

  if (!findBlog) return <p>Loading...</p>;

  return (
    <Wrapper>
      <div id="wrapper">
        <div className="content">
          <div className="breadcrumbs-header fl-wrap">
            <div className="container">
              <div className="breadcrumbs-header_url">
                <Link to="/">Home</Link>
              </div>
            </div>
            <div className="pwh_bg"></div>
          </div>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-container fl-wrap fix-container-init">
                    {/* Single Post Header */}
                    <div className="single-post-header fl-wrap">
                      <Link className="post-category-marker" to="#">
                        {findBlog.category}
                      </Link>

                      <div className="clearfix"></div>

                      <h1>{findBlog.title}</h1>

                      {/* <h4>{findBlog.description}</h4> */}

                      <div className="clearfix"></div>

                      {/* <div className="author-link">
                        <Link to="#">
                          <img
                            src={findBlog.authorImage}
                            alt="Author"
                            style={{
                              border: "1px solid #2A276C",
                            }}
                          />
                          <span>{findBlog.author}</span>
                        </Link>
                      </div> */}

                      <span className="post-date m-0 mt-1">
                        <i className="far fa-clock"></i> {findBlog.date}
                      </span>
                    </div>
                    {/* End Single Post Header */}

                    <div>
                      <div className="single-post-media fl-wrap">
                        <div className="single-slider-wrap fl-wrap">
                          {findBlog &&
                            findBlog.images &&
                            findBlog.images.length > 0 && (
                              <Swiper
                                modules={[Navigation, Pagination]}
                                navigation={{
                                  nextEl: ".ss-slider-cont-next",
                                  prevEl: ".ss-slider-cont-prev",
                                }}
                                pagination={{
                                  clickable: true,
                                  el: ".ss-slider-pagination",
                                }}
                                className="single-slider fl-wrap"
                              >
                                {findBlog.images.map((slide, index) => (
                                  <SwiperSlide key={index} className="hov_zoom">
                                    <img
                                      src={slide}
                                      alt={slide.alt || "Image"}
                                    />

                                    <button
                                      type="button"
                                      className="box-media-zoom popup-image"
                                      onClick={() => handlePopupOpen(slide)}
                                    >
                                      <i className="fas fa-search"></i>
                                    </button>

                                    {slide.copyright && (
                                      <span className="post-media_title pmd_vis">
                                        {slide.copyright}
                                      </span>
                                    )}
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            )}
                          <div className="ss-slider-controls2">
                            <div className="ss-slider-pagination pag-style"></div>
                          </div>
                          <div className="ss-slider-cont ss-slider-cont-prev">
                            <i className="fas fa-caret-left"></i>
                          </div>
                          <div className="ss-slider-cont ss-slider-cont-next">
                            <i className="fas fa-caret-right"></i>
                          </div>
                        </div>
                      </div>

                      {popupOpen && (
                        <div className="popup-overlay" style={overlayStyles}>
                          <div className="popup-content" style={contentStyles}>
                            <button
                              className="popup-close"
                              style={closeBtnStyles}
                              onClick={handlePopupClose}
                            >
                              X
                            </button>
                            <img
                              src={activeImage}
                              alt="Popup"
                              style={imageStyles}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {/* End Single Post Media Slider */}

                    {/* =========== single-post-content  ==========*/}
                    {para && para.length > 0 ? (
                      para.map((item) => {
                        return (
                          <div className="single-post-content" key={item.id}>
                            <div className="fs-wrap smpar fl-wrap"></div>
                            <div
                              className="single-post-content_text"
                              id="font_chage"
                            >
                              <h4 className="mb_head">{item.text}</h4>

                              {/* <p
                                className="has-drop-cap"
                                style={{ textAlign: "justify" }}
                              >
                                {item.description}
                              </p> */}

                              <div style={{ textAlign: "justify" }}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <br />
                          </div>
                        );
                      })
                    ) : (
                      <p>No content available</p>
                    )}

                    {/*========== single-post-content end ==========*/}
                    <div className="limit-box2 fl-wrap"></div>

                    {/* ========== post-author ============ */}
                    <div className="post-author fl-wrap">
                      <div className="author-img shadow-none">
                        <img
                          src={findBlog.authorImage}
                          alt="Author"
                          className="shadow-none"
                        />
                      </div>
                      <div className="author-content fl-wrap">
                        <h5>Written By</h5>
                        <p>{findBlog.author}</p>
                      </div>
                    </div>
                    {/*========== post-author end ============ */}
                  </div>
                </div>
              </div>
              <div className="limit-box fl-wrap"></div>
            </div>
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section``;

// popup-overlay
const overlayStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.8)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
};

const contentStyles = {
  position: "relative",
  background: "#fff",
  padding: "20px",
  borderRadius: "4px",
};

const closeBtnStyles = {
  position: "absolute",
  top: "10px",
  right: "10px",
  background: "transparent",
  border: "none",
  fontSize: "1.5rem",
  cursor: "pointer",
};

const imageStyles = {
  maxWidth: "100%",
  height: "auto",
};

export default AllIssuesDetails;
