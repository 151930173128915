import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div id="wrapper" style={{ marginTop: "0px" }}>
      <div className="content" style={{ marginTop: "0px" }}>
        <section className="hero-section error-section">
          <div className="bg-wrap hero-section_bg">
            <div
              className="bg"
              data-bg="images/bg/6.jpg"
              style={{ backgroundImage: 'url("/images/bg/6.jpg")' }}
            ></div>
          </div>
          <div className="container">
            <div className="error-wrap">
              <h2>404</h2>
              <p>
                We're sorry, but the Page you were looking for, couldn't be
                found.
              </p>
              <div className="clearfix"></div>
              <form action="#" className="custom-form">
                <input
                  name="se"
                  id="se2"
                  type="text"
                  className="search"
                  placeholder="Search.."
                  defaultValue="Search..."
                />
                <button className="search-submit color-bg" id="submit_btn2">
                  <i className="fa fa-search"></i>
                </button>
              </form>
              <div className="clearfix"></div>
              <p>Or</p>
              <Link to="/" className="btn color-btn flat-btn">
                <span>Back to Home Page</span>
                <i className="fas fa-caret-right"></i>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default NotFound;
