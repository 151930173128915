import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BsGrid } from "react-icons/bs";
import { FaBars } from "react-icons/fa6";
import { PiCaretLineLeftBold, PiCaretLineRightBold } from "react-icons/pi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import blogsArray from "../../blogData.js";

const AllIssues = () => {
  const [blogs, setBlogs] = useState([]);
  const [activeTab, setActiveTab] = useState("recent");

  useEffect(() => {
    setBlogs(blogsArray);
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const totalPages = Math.ceil(blogs.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogs.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // tabbing
  const [activeItem, setActiveItem] = useState("one");
  const [showComponent, setshowComponent] = useState("one");
  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <Wrapper style={{ padding: "0" }}>
      <div id="wrapper" style={{ padding: "0" }}>
        <div className="content" style={{ marginTop: "70px" }}>
          <div className="breadcrumbs-header fl-wrap">
            <div className="container">
              <div className="breadcrumbs-header_url">
                <Link to="/">Home</Link>
              </div>

              <div className="col-lg-3 col-md-12 col-sm-12 barrGrid d-none">
                <div className="d-flex justify-content-start align-items-center gap-4">
                  <Link
                    onClick={() => {
                      handleItemClick("one");
                      setshowComponent("one");
                    }}
                    className={`bttnn btn btn-link ${
                      activeItem === "one" ? "active" : ""
                    }`}
                  >
                    <BsGrid />
                  </Link>

                  <Link
                    onClick={() => {
                      handleItemClick("two");
                      setshowComponent("two");
                    }}
                    className={`bttnn btn btn-link ${
                      activeItem === "two" ? "active" : ""
                    }`}
                  >
                    <FaBars />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="main-container fl-wrap fix-container-init">
                    <div className="section-title">
                      <h2>Recent Publications</h2>
                      <div className="steader_opt steader_opt_abs d-none">
                        <select
                          name="filter"
                          id="list"
                          data-placeholder="Persons"
                          className="style-select no-search-select w-100"
                          style={{ padding: "13px" }}
                        >
                          <option>Latest</option>
                          <option>Most Read</option>
                          <option>Most Viewed</option>
                          <option>Most Commented</option>
                        </select>
                      </div>
                    </div>

                    {/* ======= list Posts ======= */}
                    {showComponent === "one" && (
                      <div className="list-post-wrap">
                        {currentItems &&
                          currentItems.map((news) => {
                            return (
                              <div key={news.id} className="list-post fl-wrap">
                                <div className="list-post-media">
                                  <Link to={`/all-issues-details/${news.id}`}>
                                    <div className="bg-wrap">
                                      <div
                                        className="bg"
                                        style={{
                                          backgroundImage: `url(${news.image})`,
                                        }}
                                      ></div>
                                    </div>
                                  </Link>
                                  <span className="post-media_title">
                                    &copy; {news.copyright}
                                  </span>
                                </div>
                                <div className="list-post-content">
                                  <Link className="post-category-marker" to="#">
                                    {news.category}
                                  </Link>
                                  <h3>
                                    <Link to={`/all-issues-details/${news.id}`}>
                                      {news.title}
                                    </Link>
                                  </h3>
                                  <span className="post-date">
                                    <i className="far fa-clock"></i> {news.date}
                                  </span>

                                  <div className="author-link">
                                    <a href="author-single.html">
                                      <img
                                        src={news.authorImage}
                                        alt={news.author}
                                        style={{
                                          border: "1px solid #2A276C",
                                        }}
                                      />
                                      <span>{news.author}</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}

                    {/* ====== Grid Posts ====== */}
                    {showComponent === "two" && (
                      <div className="grid-post-wrap gallery-items">
                        {currentItems &&
                          currentItems.map((post) => {
                            return (
                              <div className="gallery-item" key={post.id}>
                                <div className="grid-post-item bold_gpi fl-wrap">
                                  <div className="grid-post-media">
                                    <Link
                                      to={`/all-issues-details/${post.id}`}
                                      className="gpm_link"
                                    >
                                      <div className="bg-wrap">
                                        <div
                                          className="bg"
                                          style={{
                                            backgroundImage: `url(${post.image})`,
                                          }}
                                        ></div>
                                      </div>
                                    </Link>
                                    <span className="post-media_title">
                                      &copy; {post.copyright}
                                    </span>
                                  </div>
                                  <Link
                                    className="post-category-marker purp-bg"
                                    to="#"
                                  >
                                    {post.category}
                                  </Link>
                                  <div className="grid-post-content">
                                    <h3>
                                      <Link
                                        to={`/all-issues-details/${post.id}`}
                                      >
                                        {post.title}
                                      </Link>
                                    </h3>
                                    <span className="post-date">
                                      <i className="far fa-clock"></i>{" "}
                                      {post.date}
                                    </span>
                                  </div>
                                  <div className="grid-post-footer">
                                    <div className="author-link">
                                      <Link to="#">
                                        <img
                                          src={post.authorImage}
                                          alt={post.author}
                                          style={{
                                            border: "1px solid #2A276C",
                                          }}
                                        />
                                        <span>{post.author}</span>
                                      </Link>
                                    </div>
                                    {/* <ul className="post-opt">
                                      <li>
                                        <i className="far fa-comments-alt"></i>{" "}
                                        {post.comments}
                                      </li>
                                      <li>
                                        <i className="fal fa-eye"></i>{" "}
                                        {post.views}
                                      </li>
                                    </ul> */}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>

                  <div className="pagination d-flex justify-content-between align-items-center mt-3 w-100">
                    <div className="d-flex align-items-center gap-1">
                      <span className="pagination_text w-100">
                        Rows per page:
                      </span>
                      <select
                        className="form-select ms-2"
                        value={itemsPerPage}
                        onChange={(e) => {
                          setItemsPerPage(Number(e.target.value));
                          setCurrentPage(1);
                        }}
                      >
                        {[5, 10, 15, 20].map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                    </div>

                    <span className="pagination-info">
                      {indexOfFirstItem + 1}-
                      {Math.min(indexOfLastItem, blogs.length)} of{" "}
                      {blogs.length}
                    </span>

                    <div className="pagination-buttons d-flex gap-2">
                      <button
                        className="btn btn-link"
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                      >
                        <PiCaretLineLeftBold />
                      </button>
                      <button
                        className="btn btn-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <FaChevronLeft />
                      </button>
                      <button
                        className="btn btn-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <FaChevronRight />
                      </button>
                      <button
                        className="btn btn-link"
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                      >
                        <PiCaretLineRightBold />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="sidebar-content fl-wrap fixed-bar">
                    <div className="box-widget fl-wrap">
                      <div className="box-widget-content">
                        <div className="search-widget fl-wrap">
                          <form>
                            <input
                              name="se"
                              id="se12"
                              type="text"
                              className="search"
                              placeholder="Search..."
                              defaultValue=""
                            />
                            <button
                              className="search-submit2"
                              id="submit_btn12"
                            >
                              <i className="far fa-search"></i>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>

                    {/* ========= Active Tab ========= */}
                    <div className="box-widget fl-wrap d-none">
                      <div className="box-widget-content">
                        <div className="content-tabs-wrap tabs-act tabs-widget fl-wrap">
                          <div className="content-tabs fl-wrap">
                            <ul className="tabs-menu fl-wrap no-list-style">
                              <li
                                className={
                                  activeTab === "recent" ? "current" : ""
                                }
                                onClick={() => setActiveTab("recent")}
                              >
                                <Link to="#">Recent News</Link>
                              </li>
                              <li
                                className={
                                  activeTab === "popular" ? "current" : ""
                                }
                                onClick={() => setActiveTab("popular")}
                              >
                                <Link to="#">Popular News</Link>
                              </li>
                            </ul>
                          </div>

                          <div className="tabs-container">
                            {activeTab === "recent" && (
                              <div className="tab-content first-tab">
                                <div className="post-widget-container fl-wrap">
                                  <div className="post-widget-item fl-wrap">
                                    <div className="post-widget-item-media">
                                      <a href="post-single.html">
                                        <img
                                          src="/images/all/thumbs/1.jpg"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div className="post-widget-item-content">
                                      <h4>
                                        <a href="post-single.html">
                                          How to start Home education.
                                        </a>
                                      </h4>
                                      <ul className="pwic_opt">
                                        <li>
                                          <span>
                                            <i className="far fa-clock"></i> 25
                                            may 2022
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="far fa-comments-alt"></i>
                                            12
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="fal fa-eye"></i>654
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="post-widget-item fl-wrap">
                                    <div className="post-widget-item-media">
                                      <a href="post-single.html">
                                        <img
                                          src="/images/all/thumbs/2.jpg"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div className="post-widget-item-content">
                                      <h4>
                                        <a href="post-single.html">
                                          The secret to moving this screening.
                                        </a>
                                      </h4>
                                      <ul className="pwic_opt">
                                        <li>
                                          <span>
                                            <i className="far fa-clock"></i> 13
                                            april 2021
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="far fa-comments-alt"></i>
                                            6
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="fal fa-eye"></i>1227
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="post-widget-item fl-wrap">
                                    <div className="post-widget-item-media">
                                      <a href="post-single.html">
                                        <img
                                          src="/images/all/thumbs/3.jpg"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div className="post-widget-item-content">
                                      <h4>
                                        <a href="post-single.html">
                                          Fall ability to keep Congress on
                                          rails.
                                        </a>
                                      </h4>
                                      <ul className="pwic_opt">
                                        <li>
                                          <span>
                                            <i className="far fa-clock"></i> 02
                                            December 2021
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="far fa-comments-alt"></i>
                                            12
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="fal fa-eye"></i>654
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="post-widget-item fl-wrap">
                                    <div className="post-widget-item-media">
                                      <Link to="#">
                                        <img
                                          src="/images/all/thumbs/4.jpg"
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                    <div className="post-widget-item-content">
                                      <h4>
                                        <Link to="#">
                                          Innovations that Bring Aesthetic
                                          Pleasure to All.
                                        </Link>
                                      </h4>
                                      <ul className="pwic_opt">
                                        <li>
                                          <span>
                                            <i className="far fa-clock"></i> 30
                                            september 2021
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="far fa-comments-alt"></i>
                                            44
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="fal fa-eye"></i>
                                            684
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {activeTab === "popular" && (
                              <div className="tab-content2 second-tab">
                                <div className="post-widget-container fl-wrap">
                                  <div className="post-widget-item fl-wrap">
                                    <div className="post-widget-item-media">
                                      <a href="post-single.html">
                                        <img
                                          src="/images/all/thumbs/5.jpg"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div className="post-widget-item-content">
                                      <h4>
                                        <a href="post-single.html">
                                          Magpie nesting zone sites.
                                        </a>
                                      </h4>
                                      <ul className="pwic_opt">
                                        <li>
                                          <span>
                                            <i className="far fa-clock"></i> 05
                                            april 2021
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="far fa-comments-alt"></i>
                                            16
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="fal fa-eye"></i>727
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="post-widget-item fl-wrap">
                                    <div className="post-widget-item-media">
                                      <a href="post-single.html">
                                        <img
                                          src="/images/all/thumbs/6.jpg"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div className="post-widget-item-content">
                                      <h4>
                                        <a href="post-single.html">
                                          Locals help create whole new
                                          community.
                                        </a>
                                      </h4>
                                      <ul className="pwic_opt">
                                        <li>
                                          <span>
                                            <i className="far fa-clock"></i> 22
                                            march 2021
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="far fa-comments-alt"></i>
                                            31
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="fal fa-eye"></i>63
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="post-widget-item fl-wrap">
                                    <div className="post-widget-item-media">
                                      <a href="post-single.html">
                                        <img
                                          src="/images/all/thumbs/7.jpg"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div className="post-widget-item-content">
                                      <h4>
                                        <a href="post-single.html">
                                          Tennis season still to proceed.
                                        </a>
                                      </h4>
                                      <ul className="pwic_opt">
                                        <li>
                                          <span>
                                            <i className="far fa-clock"></i> 06
                                            December 2021
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="far fa-comments-alt"></i>
                                            05
                                          </span>
                                        </li>
                                        <li>
                                          <span>
                                            <i className="fal fa-eye"></i>145
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box-widget fl-wrap d-none">
                      <div className="box-widget-content">
                        <div className="banner-widget fl-wrap">
                          <div className="bg-wrap bg-parallax-wrap-gradien">
                            <div
                              className="bg"
                              style={{
                                backgroundImage: `url(/images/all/7.jpg)`,
                              }}
                            ></div>
                          </div>
                          <div className="banner-widget_content">
                            <h5>
                              Visit our awesome merch and souvenir online shop.
                            </h5>
                            <Link
                              to="#"
                              className="btn float-btn color-bg small-btn"
                            >
                              Our shop
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="limit-box fl-wrap"></div>
            </div>
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .btn {
    /* background: none !important; */
  }
  .bttnn {
    color: #f00808;
    border-radius: 4px;
    border-color: #fa0808;
    padding: 4px 10px;
    cursor: pointer;
    transition: 0.4s all ease-in-out;
  }

  .bttnn:hover {
    background-color: #f30808;
    color: #fff;
  }

  /* Active state styles */
  .bttnn.active {
    background-color: #f30808;
    color: #fff;
    border-color: #fa0808;
  }

  /* ===== pagination css ===== */
  .pagination {
    margin: 20px 0 0 0;
    text-align: center;
    float: left;
    z-index: 10;
    position: relative;
    /* background: #222; */
    background: none !important;
    overflow: hidden;
    border-radius: 4px;
  }
  .form-select {
    padding: 6px 6px !important;
  }
  .btn {
    padding: 10px 14px !important;
  }

  .btn-link {
    background-color: #2a276c !important;
    color: #fff !important;
    border: none;
    cursor: pointer;
  }
  .btn-link:hover {
    background-color: #424141 !important;
    color: #fff;
  }
  .d-flex {
    display: flex;
  }
  .gap-2 {
    gap: 8px;
  }
  @media screen and (max-width: 425px) {
    .texxt {
      font-size: 10px !important;
    }
    .pagination_text {
      display: none !important;
    }
    .pagination-info {
      font-size: 10px !important;
    }
    .btn-link {
      padding: 2px 8px !important;
    }
    .form-select {
      padding: 6px 20px !important;
    }
    .btn {
      padding: 11px 15px !important;
    }
  }
  @media screen and (max-width: 320px) {
    .pagination-buttons {
      gap: 5px !important;
    }
    .form-select {
      padding: 6px 20px !important;
    }
    .btn {
      padding: 10px 12px !important;
    }
  }
`;

export default AllIssues;
